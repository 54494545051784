<template>
  <Card>
    <Form
      class="stacked-form"
      ref="usersAddForm"
      :model="form"
      label-position="left"
      :label-width="100"
    >
      <Row
        type="flex"
        :gutter="40"
      >
        <Col
          :xs="24"
          :md="13"
          class="small-widget-col"
        >
          <Row>
            <Col span="24">
              <div class="tile">
                <Divider
                  plain
                  orientation="left"
                >
                  User identity
                </Divider>
                <div class="tile-block">
                  <FormItem
                    prop="name"
                    label="Name"
                  >
                    <Input
                      type="text"
                      placeholder="Name"
                      @on-change="updateNameField"
                      v-model="form.name"
                    />
                  </FormItem>
                  <FormItem
                    prop="email"
                    label="@ e-mail"
                  >
                    <Input
                      type="email"
                      placeholder="Email"
                      v-model="form.email"
                    />
                  </FormItem>
                  <FormItem
                    prop="jobTitle"
                    label="Job title"
                  >
                    <Input
                      type="text"
                      placeholder="Job title"
                      v-model="form.jobTitle"
                    />
                  </FormItem>
                  <FormItem
                    prop="organisation"
                    label="Organisation"
                  >
                    <Input
                      type="text"
                      placeholder="User organisation"
                      v-model="form.organisation"
                    />
                  </FormItem>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        type="flex"
        :gutter="40"
      >
        <Col
          :xs="24"
          :md="13"
          class="small-widget-col"
        >
          <div class="tile">
            <!--            <div class="tile-header tile-header&#45;&#45;simple">-->
            <!--              <span class="tile-header-title">User Access</span>-->
            <!--            </div>-->
            <Divider
              plain
              orientation="left"
            >
              User Access
            </Divider>
            <div class="tile-block">
              <div>
                <FormItem
                  prop="products"
                  label="Products"
                >
                  <Select
                    multiple
                    placeholder="Products"
                    @on-change="updateFormRoles"
                    v-model="form.products"
                  >
                    <Option
                      :value="role.id"
                      v-for="role in options.products"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem
                  prop="roles"
                  label="Roles"
                >
                  <Select
                    multiple
                    :disabled="!form.products.length"
                    placeholder="Roles"
                    v-model="form.roles"
                  >
                    <Option
                      :value="role.id"
                      v-for="role in formOptions.roles"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </Option>
                  </Select>
                </FormItem>

                <FormItem
                  label="Properties"
                  prop="properties"
                >
                  <Select
                    multiple
                    filterable
                    :disabled="!form.roles.length"
                    @on-query-change="searchForProperties"
                    placeholder="Properties"
                    v-model="form.properties"
                  >
                    <Option
                      :value="property.id"
                      v-for="property in options.properties"
                      :key="property.id"
                    >
                      {{ property.displayName }}
                    </Option>
                  </Select>
                </FormItem>
              </div>
            </div>
            <div class="tile-block">
              <div class="form-actions">
                <Button
                  style="float:right;"
                  type="primary"
                  class="btn btn--elevated"
                  @click="handleSubmit('usersAddForm')"
                >
                  Add user
                </Button>
                <Button
                  style="float:right;"
                  @click="handleReset('usersAddForm')"
                  type="text"
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  </Card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AdminUsersView',
  data () {
    return {
      showPassword: 'text',
      options: {
        roles: [],
        properties: [],
        products: []
      },
      formOptions: {
        roles: [],
        properties: [],
        products: []
      },
      form: {
        status: true,
        organisation: '',
        name: '',
        email: '',
        // password: this.generatePassword(),
        jobTitle: '',
        products: [],
        roles: [],
        properties: [],
        weeklyReportProperties: []
        // superAdminState: false
      }
    }
  },
  computed: {
    // TODO CLEAN THAT
    // superAdmin () {
    //   return this.form.superAdminState
    // }
  },
  methods: {
    ...mapActions({
      getProperties: 'property/getProperties'
    }),
    updateNameField (e) {
      this.form.name = this.form.name.trim()
    },

    searchForProperties (query) {
      return this.getProperties({q: query, limit: 50}).then( ({data}) => {
        this.options.properties = data
      })
    },
    generatePassword () {
      const length = 3,
        charset = `abcdefghijklmnopqrstuvwxyz`,
        charsetM = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`,
        chartsetL = "0123456789",
        chartsetU = `"'(§!)-$^=:;,@#`
      let retVal = ""
      for (let i = 0;i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * charset.length))
        retVal += chartsetL.charAt(Math.floor(Math.random() * chartsetL.length))
        retVal += chartsetU.charAt(Math.floor(Math.random() * chartsetU.length))
        retVal += charsetM.charAt(Math.floor(Math.random() * charsetM.length))
      }
      return retVal
    },
    updateFormRoles () {
      this.form.roles = this.form.roles
        .filter(({productId}) => this.form.products.includes(productId))

      this.formOptions.roles = this.options.roles
        .filter(({productId}) => this.form.products.includes(productId))
    },
    async fetch () {
      const responses = await Promise.all([
        this.$axios.get('users/getRoles'),
        this.getProperties({limit:50}),
        this.$axios.get('users/getProducts')
      ])

      const [
        roles,
        properties,
        products
      ] = responses.map(({data}) => Object.values(data?.widget?.data || data))

      this.options = {
        roles,
        properties,
        products
      }
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    },
    handleSubmit (name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          this.isUpdating = true
          this.form.name = this.form.name.trim()
          const payload = {
            ...this.form,
            status: this.form.status ? 'enabled' : 'disabled'
          }

          try {
            const responseData = await this.$axios.post('/users/addjson/', payload)
            this.isUpdating = false
            if (responseData.data.success) {
              this.$Message.success('User have been created. An Email has been sent to the user to create a password')
              this.$Notice.open({
                title: this.$t('global.user.watch_box'),
                desc: "An Email has been sent to the user to create a password"
              })
              this.$router.push({path: '/admin/users'})
            } else {
              this.$Message.error('An error has occurred...')
            }
          } catch (err) {
            this.isUpdating = false
            this.$Message.error('Server sent back an error...')
            console.error('handleSubmit', err)
          }
        } else {
          this.$Message.error('The form is invalid, please verify it')
        }
      })
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>
