var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', [_c('Form', {
    ref: "usersAddForm",
    staticClass: "stacked-form",
    attrs: {
      "model": _vm.form,
      "label-position": "left",
      "label-width": 100
    }
  }, [_c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 40
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "md": 13
    }
  }, [_c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('div', {
    staticClass: "tile"
  }, [_c('Divider', {
    attrs: {
      "plain": "",
      "orientation": "left"
    }
  }, [_vm._v(" User identity ")]), _c('div', {
    staticClass: "tile-block"
  }, [_c('FormItem', {
    attrs: {
      "prop": "name",
      "label": "Name"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    on: {
      "on-change": _vm.updateNameField
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "email",
      "label": "@ e-mail"
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "placeholder": "Email"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "jobTitle",
      "label": "Job title"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "Job title"
    },
    model: {
      value: _vm.form.jobTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jobTitle", $$v);
      },
      expression: "form.jobTitle"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "organisation",
      "label": "Organisation"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "User organisation"
    },
    model: {
      value: _vm.form.organisation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "organisation", $$v);
      },
      expression: "form.organisation"
    }
  })], 1)], 1)], 1)])], 1)], 1)], 1), _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 40
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "md": 13
    }
  }, [_c('div', {
    staticClass: "tile"
  }, [_c('Divider', {
    attrs: {
      "plain": "",
      "orientation": "left"
    }
  }, [_vm._v(" User Access ")]), _c('div', {
    staticClass: "tile-block"
  }, [_c('div', [_c('FormItem', {
    attrs: {
      "prop": "products",
      "label": "Products"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "placeholder": "Products"
    },
    on: {
      "on-change": _vm.updateFormRoles
    },
    model: {
      value: _vm.form.products,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "products", $$v);
      },
      expression: "form.products"
    }
  }, _vm._l(_vm.options.products, function (role) {
    return _c('Option', {
      key: role.id,
      attrs: {
        "value": role.id
      }
    }, [_vm._v(" " + _vm._s(role.name) + " ")]);
  }), 1)], 1), _c('FormItem', {
    attrs: {
      "prop": "roles",
      "label": "Roles"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "disabled": !_vm.form.products.length,
      "placeholder": "Roles"
    },
    model: {
      value: _vm.form.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roles", $$v);
      },
      expression: "form.roles"
    }
  }, _vm._l(_vm.formOptions.roles, function (role) {
    return _c('Option', {
      key: role.id,
      attrs: {
        "value": role.id
      }
    }, [_vm._v(" " + _vm._s(role.name) + " ")]);
  }), 1)], 1), _c('FormItem', {
    attrs: {
      "label": "Properties",
      "prop": "properties"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "filterable": "",
      "disabled": !_vm.form.roles.length,
      "placeholder": "Properties"
    },
    on: {
      "on-query-change": _vm.searchForProperties
    },
    model: {
      value: _vm.form.properties,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "properties", $$v);
      },
      expression: "form.properties"
    }
  }, _vm._l(_vm.options.properties, function (property) {
    return _c('Option', {
      key: property.id,
      attrs: {
        "value": property.id
      }
    }, [_vm._v(" " + _vm._s(property.displayName) + " ")]);
  }), 1)], 1)], 1)]), _c('div', {
    staticClass: "tile-block"
  }, [_c('div', {
    staticClass: "form-actions"
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit('usersAddForm');
      }
    }
  }, [_vm._v(" Add user ")]), _c('Button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        return _vm.handleReset('usersAddForm');
      }
    }
  }, [_vm._v(" Reset ")])], 1)])], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }